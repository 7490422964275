import Uatu from '../src/utility/uatu'
window.Uatu = Uatu

window.Logger = (text, key) => {
  if (window.location.href.includes('debug') || window.location.origin.includes('localhost')  || window.location.origin.includes('dev')) {
    console.log(text, key)
  }
}

import "@hotwired/turbo-rails"
window.Turbo.start()
window.Turbo.session.drive = false

// import Rails from '@rails/ujs';
// Rails.start()
// window.Rails = Rails


// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()

// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

import '../controllers'
import '../channels'

// Vendors
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'notyf/notyf.min.css'
import 'aos/dist/aos.css';
import 'animate.css/animate.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';

import debounced from 'debounced'
debounced.initialize(['input', 'mouseup']) // I think there's a bug in debounced because if I don't do this, debounced:input doesn't fire
