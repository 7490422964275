import { Controller } from "@hotwired/stimulus"

const DEFAULT_CONFIG = {
  hideDelay: 700,
  animations: {
    show: 'animate__fadeIn',
    duration: 'animate__faster'
  }
}

export default class extends Controller {
  static targets = ['nav']

  connect() {
    this.config = { ...DEFAULT_CONFIG, ...this.element.dataset }

    this.navTarget.classList.add(
      'animate__animated',
      this.config.animations.duration
    )

    // Find the trigger element - either a button or the container itself
    this.triggerElement = this.element.querySelector('button') || this.element

    // Add hover events safely
    this.addHoverEvents(this.element)
    this.addHoverEvents(this.navTarget)

    document.addEventListener(`${this.identifier}:hideOthers`,
      this.hideIfNotThis = (e) => e.target !== this.element && this.hide())
  }

  disconnect() {
    document.removeEventListener(`${this.identifier}:hideOthers`, this.hideIfNotThis)
  }

  toggle() {
    this.isVisible ? this.hide() : this.show()
  }

  show() {
    this.dispatch('hideOthers')
    this.navTarget.classList.remove('hide')
    this.navTarget.classList.add(this.config.animations.show)
  }

  hide() {
    this.navTarget.classList.add('hide')
    this.navTarget.classList.remove(this.config.animations.show)
  }

  get isVisible() {
    return !this.navTarget.classList.contains('hide')
  }

  initDelayedHide(e) {
    this.hideTimer = window.setTimeout(() => {
      this.hide(e)
      this.hideTimer = undefined
    }, this.config.hideDelay)
  }

  cancelDelayedHide(e) {
    if (!this.hideTimer) { return }
    window.clearTimeout(this.hideTimer)
    this.hideTimer = undefined
  }

  addHoverEvents(element) {
    if (!element) return // Guard against null elements

    element.addEventListener('mouseleave', () => {
      this.hideTimer = setTimeout(() => {
        this.hide()
        this.hideTimer = null
      }, this.config.hideDelay)
    })

    element.addEventListener('mouseenter', () => {
      if (this.hideTimer) {
        clearTimeout(this.hideTimer)
        this.hideTimer = null
      }
    })
  }
}
