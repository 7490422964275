import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["body"]

  setQuillText(e) {
    e.preventDefault()

    window.quill.root.innerHTML = '';
    window.quill.clipboard.dangerouslyPasteHTML(0, this.bodyTarget.innerHTML);
  }
}
