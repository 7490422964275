import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["details"]

  connect() {
    console.log("Group controller connected");
  }

  showDetailsModal(event) {
    window.Modal.show('modal-group-details')
  }

  hideDetailsModal(event) {
    console.log("hideDetailsModal")
    window.Modal.close('modal-group-details')
  }
}
