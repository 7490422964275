import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    eventName: String,
    targetId: String
  }

  // a self-destructing controller that first dispatches an event to a target element
  // see https://boringrails.com/articles/self-destructing-stimulus-controllers/
  connect() {
    this.dispatchEvent()
    this.element.remove()
  }

  dispatchEvent() {
    this.target.dispatchEvent(new CustomEvent(this.eventNameValue, { bubbles: true, cancelable: true }))
  }

  get target() {
    if (!this.hasTargetIdValue) { return this.element }
    
    const target = document.getElementById(this.targetIdValue)
    if (!target) { return this.element }

    return target
  }
}
