const UatuInit = {
  on: (eventNames, action, options) => {
    options = { ...options, passive: true }
    eventNames = (typeof eventNames == 'string') ? [eventNames] : eventNames;
    eventNames.forEach(eventName => {
      window.addEventListener(eventName, event => {
        // Logger(`Uatu: received event ${eventName}`, event)
        action(event.detail)
      }, options)
    })
  },
  emit: (eventName, data) => {
    // Logger(`Uatu: dispatched event ${eventName}`)
    window.dispatchEvent(new CustomEvent(eventName, { detail: data }))
  }
}

export default UatuInit
