import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link", "subnav"]

  connect() {
    this.#addEventListeners()
  }

  disconnect() {
    this.#removeEventListeners()
  }
  toggle(event) {
    event.stopPropagation()
    this.#isOpen ? this.#close() : this.#openMenu()
  }

  // Private methods
  #openMenu() {
    this.#notifyOtherMenus()
    this.#showMenu()
    this.#highlightLink()
  }

  #close() {
    this.#hideMenu()
    this.#unhighlightLink()
  }

  #handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.#close()
    }
  }

  #handleOtherMenuOpened(event) {
    if (this.element.id !== event.detail.exceptId) {
      this.#close()
    }
  }

  get #isOpen() {
    return this.subnavTarget.classList.contains('opacity-100')
  }

  #showMenu() {
    this.subnavTarget.classList.add('opacity-100')
    this.subnavTarget.classList.remove('pointer-events-none')
    this.subnavTarget.style.zIndex = "100"
  }

  #hideMenu() {
    this.subnavTarget.classList.remove('opacity-100')
    this.subnavTarget.classList.add('pointer-events-none')
    this.subnavTarget.style.zIndex = ""
  }

  #highlightLink() {
    this.linkTarget.classList.add('text-pink-600')
  }

  #unhighlightLink() {
    this.linkTarget.classList.remove('text-pink-600')
  }

  #notifyOtherMenus() {
    document.dispatchEvent(new CustomEvent("header-nav:opened", {
      detail: { exceptId: this.element.id }
    }))
  }

  #addEventListeners() {
    this.boundClickOutside = this.#handleClickOutside.bind(this)
    this.boundOtherMenuOpened = this.#handleOtherMenuOpened.bind(this)

    document.addEventListener("click", this.boundClickOutside)
    document.addEventListener("header-nav:opened", this.boundOtherMenuOpened)
  }

  #removeEventListeners() {
    document.removeEventListener("click", this.boundClickOutside)
    document.removeEventListener("header-nav:opened", this.boundOtherMenuOpened)
  }
}
