import { Controller } from "@hotwired/stimulus"
import { marked } from 'marked'
import isMobile from "../src/utility/isMobile";

export default class extends Controller {
  static targets = ["form", "input", "answer", "actions", "resources", "submitButton", "closeButton", "chatWrap", "closeIcon", "openIcon", "rating"]
  static classes = ["minimized"]

  connect() {
    this.parsedAnswer = null;
    this.resources = [];
    this.loadChatState();
    this.inputTarget.addEventListener('input', this.handleInput.bind(this));
  }

  loadChatState() {
    const storedState = JSON.parse(localStorage.getItem('chatState'));

    if (storedState) {
      this.setChatMinimized(storedState.minimized);
      if (storedState.content) {
        this.loadChatContent(storedState.content);
      }
    } else {
      // Initializing state if not present
      this.initializeChatState();
    }
  }

  initializeChatState() {
    const initialState = { minimized: false, content: { question: '', answer: '', resources: '' } };
    localStorage.setItem('chatState', JSON.stringify(initialState));
    this.setChatMinimized(isMobile());
  }

  async submit(event) {
    event.preventDefault();

    const question = this.inputTarget.value.trim();

    if (!question) {
      alert('Please enter a question before submitting.');
      return;
    }

    this.disableSubmitButton();

    try {
      const response = await fetch('/chat/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({ question })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const { answer, actions, related_resources } = data;

      this.resources = related_resources;
      this.displayAnswer(answer);
      this.displayResources(this.resources);
      actions && this.displayActions(actions);
      this.closeButtonTarget.classList.remove('hidden');
      this.ratingTarget.classList.remove('hidden');  // Show the rating

      // Store the chat content
      this.storeChatContent(question, answer, this.resources);
    } catch (error) {
      console.error('Error:', error);
      this.answerTarget.innerHTML = `
        <div class="p-4 text-red-500">
          Sorry, an error occurred while processing your request. Please try again later.
        </div>
      `;
      this.resourcesTarget.innerHTML = '';
    } finally {
      this.enableSubmitButton();
    }
  }

  displayAnswer(answer) {
    this.parsedAnswer = marked.parse(answer);
    this.answerTarget.innerHTML = `<div class="p-4">${this.parsedAnswer}</div>`;
  }

  displayResources(resources) {
    this.resourcesTarget.classList.add('mt-6', 'pt-4', 'border-t', 'border-indigo-500');

    if (resources && resources.length > 0) {
      const resourcesList = resources.map(resource =>
        `<li><a href="${resource.url}" target="_blank" class="text-white hover:underline">${resource.title}</a></li>`
      ).join('');

      this.resourcesTarget.innerHTML = `
        <h3 class="font-black text-white text-lg mt-0 mb-1"><i class="far fa-link"></i> Learn more:</h3>
        <ul class="list-disc pl-5">
          ${resourcesList}
        </ul>
      `;
    } else {
      this.resourcesTarget.innerHTML = '';
    }
  }

  displayActions(actions) {
    if (actions && actions.length > 0) {
      this.actionsTarget.classList.remove('hidden');

      const actionsList = actions.map(action =>
        `<a href="${action.link}" class="no-underline no-wrap bg-gray-900 rounded-full hover:bg-gray-200 focus:bg-gray-200 text-white hover:text-black focus:text-black p-2 px-4 font-medium text-xs mr-4 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white">${action.title}</a>`
      ).join('');

      this.actionsTarget.innerHTML = `
        <div class="flex flex-wrap p-2 pt-0 text-center">
          ${actionsList}
        </div>
      `;
    } else {
      this.actionsTarget.innerHTML = '';
      this.actionsTarget.classList.add('hidden');
    }
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.add('bg-gray-400');
    this.submitButtonTarget.classList.remove('bg-blue-500', 'hover:bg-blue-600');
    this.submitButtonTarget.innerHTML = `<i class="fas fa-spinner fa-spin"></i> Generating reply...`;
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove('bg-gray-400');
    this.submitButtonTarget.classList.add('bg-blue-500', 'hover:bg-blue-600');
    this.submitButtonTarget.textContent = 'Ask';
  }

  reset({ excludeQuestionInput = false } = {}) {
    this.answerTarget.innerHTML = '';
    this.actionsTarget.classList.add('hidden');
    this.resourcesTarget.innerHTML = '';
    this.closeButtonTarget.classList.add('hidden');
    this.resourcesTarget.classList.remove('mt-6', 'pt-4', 'border-t', 'border-indigo-500');
    this.ratingTarget.classList.add('hidden');  // Hide the rating

    if (!excludeQuestionInput) {
      this.inputTarget.value = '';
    }

    const chatContent = excludeQuestionInput
      ? { question: this.inputTarget.value, answer: '', resources: '' }
      : { question: '', answer: '', resources: '' };

    this.storeChatState(false, chatContent);
  }

  toggleMinimize() {
    const isCurrentlyMinimized = this.chatWrapTarget.classList.contains('hidden');
    const newMinimizedState = !isCurrentlyMinimized;

    // Toggle the minimized state visually
    this.setChatMinimized(newMinimizedState);

    // Store state only when the button is clicked
    this.storeChatState(newMinimizedState, newMinimizedState ? { question: '', answer: '', resources: '' } : this.getChatContent());
  }

  setChatMinimized(isMinimized) {
    if (isMinimized) {
      this.chatWrapTarget.classList.add('hidden');
      this.closeIconTarget.classList.add('hidden');
      this.openIconTarget.classList.remove('hidden');
    } else {
      this.chatWrapTarget.classList.remove('hidden');
      this.closeIconTarget.classList.remove('hidden');
      this.openIconTarget.classList.add('hidden');
    }
  }

  storeChatContent(question, answer) {
    const chatContent = { question, answer, resources: this.resources };
    this.storeChatState(false, chatContent);
  }

  loadChatContent(content) {
    const { question, answer, resources } = content;
    if (question || answer) {
      this.inputTarget.value = question;
      this.displayAnswer(answer);
      this.displayResources(resources);
      this.closeButtonTarget.classList.remove('hidden');
    }
  }

  getChatContent() {
    return {
      question: this.inputTarget.value,
      answer: this.parsedAnswer,
      resources: this.resourcesTarget.innerHTML
    };
  }

  storeChatState(minimized, content) {
    const existingState = JSON.parse(localStorage.getItem('chatState')) || {};
    const chatState = { minimized, content: content || existingState.content };
    localStorage.setItem('chatState', JSON.stringify(chatState));
  }

  handleInput() {
    if (this.answerTarget.innerHTML || !this.resourcesTarget.classList.contains('hidden')) {
      this.reset({ excludeQuestionInput: true });  // Reset everything except the input
    }
  }
}
