import { Controller } from "@hotwired/stimulus"
import { Notyf } from "notyf"

export default class extends Controller {
  connect() {
    this.notyf = new Notyf({
      duration: 5000,
      dismissible: true,
      position: {
        x: 'center',
        y: 'bottom',
      }
    });
  }

  enableEditing(event) {
    const input = event.target.closest("form").querySelector("input[type='text']")
    input.readOnly = false
    input.focus()
  }

  save(event) {
    const form = event.target.closest("form")

    if (event.type === "change") {
      form.requestSubmit()
      return
    }

    if (event.type === "blur") {
      event.preventDefault()
      form.requestSubmit()

      this.notyf.success("Label updated")
    }
  }
}
