// from https://github.com/bullet-train-co/bullet_train-core/blob/main/bullet_train-fields/app/javascript/controllers/dependable_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "searchField", "sortField", "directionField", "layoutField", "labelField" ]

  updateFrameParams(e) {
    let { name } = e.detail?.event?.currentTarget
    switch (name) {
      case "search": this.updateSearchFields(e); break;
      case "sort:direction": this.updateSortFields(e); break;
      case "layout": this.updateLayoutField(e); break;
      case "label": this.updateLabelField(e); break;
    }
    this.refreshFrame()
  }

  refreshFrame() {
    this.form.requestSubmit()
  }

  updateLayoutField(e) {
    const selectedLayout = e.detail?.event?.currentTarget?.dataset?.layout
    this.layoutFieldTarget.value = selectedLayout
    window.recordAhoy.now({ event: `script_index_filter:layout:${selectedLayout}` })
  }

  updateSearchFields(e) {
    this.searchFieldTarget.value = e.detail?.event?.target?.value
    window.recordAhoy.now({ event: `script_index_filter:search` })
  }

  updateSortFields(e) {
    const [sort, direction] = e.detail?.event?.target?.value.split(":")
    this.sortFieldTarget.value = sort
    this.directionFieldTarget.value = direction
    window.recordAhoy.now({ event: `script_index_filter:sort:${direction}` })
  }

  updateLabelField(e) {
    const { value } = e.detail?.event?.target
    this.labelFieldTarget.value = value
    window.recordAhoy.now({ event: `script_index_filter:label:${value}` })
  }

  get form() {
    return this.hasFormTarget ? this.formTarget : this.element
  }
}
