// app/javascript/controllers/timezone_controller.js
import { Controller } from "@hotwired/stimulus"
import { DateTime } from "luxon"

export default class extends Controller {
  static targets = ["select"]

  connect() {
    if (!this.hasUserTimezone() && this.isOnboarding()) {
      this.setDefaultTimezone();
    }
  }

  isOnboarding() {
    return window.location.pathname == '/account/onboarding'
  }

  hasUserTimezone() {
    return this.selectTarget.value !== ''
  }

  setDefaultTimezone() {
    console.log('setDefaultTimezone')
    const userTimezone = DateTime.local().zoneName;
    const options = this.selectTarget.querySelectorAll("option");
    options.forEach((option) => {
      if (option.dataset.ianaTz === userTimezone) {
        this.selectTarget.value = option.value;
      }
    });
  }

  locate() {
    console.log('locate')
    this.setDefaultTimezone();
  }
}
