import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    videoId: String
  }

  static targets = ["button"]

  async download(event) {
    event.preventDefault()

    const button = this.buttonTarget
    const originalText = button.innerHTML
    button.innerHTML = "Downloading..."
    button.disabled = true

    try {
      const response = await fetch(`/account/video/download-url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          video_id: this.videoIdValue
        })
      })

      const data = await response.json()

      if (data.download_url) {
        const downloadUrl = data.download_url + "?download=" + document.querySelector('[data-file-name]').dataset.fileName
        console.log(downloadUrl)
        window.location.href = downloadUrl
      } else {
        throw new Error('No download URL received')
      }
    } catch (error) {
      console.error('Download failed:', error)
      alert('Failed to start download. Please try again.')
    } finally {
      button.innerHTML = originalText
      button.disabled = false
    }
  }
}
