import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["text_tools", "colors"]

  connect() {

  }

  mouseDown(event) {
    this.startX = event.clientX;
    this.startY = event.clientY;
  }

  mouseUp(event) {
    this.checkSelection();
    this.adjustToolsPosition();
  }

  checkSelection() {
    const selection = window.getSelection();
    if (!selection.isCollapsed && this.isSelectionWithinEditor(selection)) {

      this.text_toolsTarget.style.display = 'block'; // Ensure it's visible if there's a selection
      this.text_toolsTarget.classList.remove('hide')
    } else {
      this.text_toolsTarget.classList.add('hide')
      this.colorsTarget.classList.add('hide') // hide the colors too
    }
  }

  addBrackets() {
    const range = window.quill.getSelection(true);
    if (range && range.length !== 0) {
        const text = window.quill.getText(range.index, range.length);
        const bracketedText = `[${text}]`;
        window.quill.deleteText(range.index, range.length, 'user');
        window.quill.insertText(range.index, bracketedText, 'user');
    }
  }

  adjustToolsPosition() {
    const selection = window.getSelection();
    if (!selection.isCollapsed) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      // Include window scroll offsets
      let scrollX = window.scrollX || window.pageXOffset;
      let scrollY = window.scrollY || window.pageYOffset;

      // Calculate the position to place the tools element above the selection
      let toolsTop = rect.top + scrollY - this.text_toolsTarget.offsetHeight - 10; // 10px above the selection
      let toolsLeft = rect.left + scrollX + (rect.width / 2) - (this.text_toolsTarget.offsetWidth / 2); // Centered above the selection

      // Adjust if the tools element goes above the visible area of the viewport
      if (toolsTop < scrollY) {
          toolsTop = rect.bottom + scrollY + 10; // Place below the selection if not enough space above
      }

      // Adjust toolsLeft to keep the tools on-screen
      const toolsRight = toolsLeft + this.text_toolsTarget.offsetWidth;
      if (toolsLeft < scrollX) {
          toolsLeft = scrollX + 5; // 5px from the left edge of the viewport
      } else if (toolsRight > window.innerWidth) {
          toolsLeft = window.innerWidth - this.text_toolsTarget.offsetWidth - 5; // 5px from the right edge of the viewport
      }

      this.text_toolsTarget.style.position = 'absolute';
      this.text_toolsTarget.style.left = `${toolsLeft}px`;
      this.text_toolsTarget.style.top = `${toolsTop}px`;
    }
}


  isSelectionWithinEditor(selection) {
    if (!selection.rangeCount) return false;
    const range = selection.getRangeAt(0);
    const editor = this.element;
    let node = range.commonAncestorContainer;
    // Ensure node is an element if it's a text node
    if (node.nodeType === Node.TEXT_NODE) {
      node = node.parentNode;
    }
    return editor.contains(node);
  }

  revealColors() {
    this.colorsTarget.classList.toggle('hide')
  }

  applyColor(event) {
    let color = event.currentTarget.dataset.color;
    const range = window.quill.getSelection();
    if (range && range.length != 0) {
      if (color == 'black') {
        color = ''
      }
      window.quill.format('color', color);
      window.recordAhoy.now({ event: `text_tools:color` })
    }
  }

  toggleBold() {
    this.toggleFormat('bold');
  }

  toggleItalic() {
      this.toggleFormat('italic');
  }

  toggleUnderline() {
      this.toggleFormat('underline');
  }

  toggleFormat(format) {
    const range = window.quill.getSelection();
    if (range && range.length !== 0) {
      const currentFormat = window.quill.getFormat(range.index, range.length);
      const isActive = !!currentFormat[format];
      window.quill.format(format, !isActive);
      window.recordAhoy.now({ event: `text_tools:${format}` })
    }
  }
}
