import { Controller } from "@hotwired/stimulus"
import { Notyf } from 'notyf';

export default class extends Controller {
  static values = {
    message: String,
    isError: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.displayNotification()
  }

  displayNotification() {
    if (!this.hasMessageValue) { return }

    this.notyf = new Notyf({
      position:{x:'center',y:'top'}
    });

    if (this.isErrorValue) {
      this.notyf.error(this.messageValue)
    } else {
      this.notyf.success(this.messageValue)
    }
  }
}