import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['colorPreview', 'field', 'noColorButton', 'colorButton']
  static classes = ['selected', 'darkIcon', 'lightIcon', 'defaultIcon']
  static values = {
    color: String,
    useDarkIcon: Boolean
  }

  initialize() {
    this.isInitializing = true
  }

  connect() {
    setTimeout(() => {
      this.isInitializing = false
    }, 0)
  }

  selectNoColor(event) {
    event.preventDefault()
    this.colorValue = ""
  }

  selectColor(event) {
    event.preventDefault()
    const { color, useDarkIcon } = event.params
    this.useDarkIconValue = useDarkIcon
    this.colorValue = color
  }

  colorValueChanged(value, previousValue) {
    this.updateFieldValue({ dispatchChange: !this.isInitializing && value !== previousValue })
    this.updateColorPreview()
    this.highlightSelectedButton()
  }

  updateFieldValue({ dispatchChange = false } = {}) {
    if (!this.hasFieldTarget) { return }
    this.fieldTarget.value = this.colorValue
    if (dispatchChange) {
      this.fieldTarget.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

  updateColorPreview() {
    if (!this.hasColorPreviewTarget) { return }
    this.colorPreviewTarget.style.backgroundColor = this.colorValue

    this.updateIconForContrastAgainstSelectedColor()
  }

  updateIconForContrastAgainstSelectedColor() {
    if (this.colorValue === "") {
      this.useDefaultIconClasses()
    } else {
      if (this.useDarkIconValue) {
        this.useDarkIconClasses()
      } else {
        this.useLightIconClasses()
      }
    }
  }

  highlightSelectedButton() {
    if (this.hasNoColorButtonTarget) {
      if (this.colorValue === "") {
        this.noColorButtonTarget.classList.add(...this.selectedClasses)
      } else {
        this.noColorButtonTarget.classList.remove(...this.selectedClasses)
      }
    }
    this.colorButtonTargets.forEach(button => {
      if (button.dataset.color === this.colorValue) {
        button.classList.add(...this.selectedClasses)
      } else {
        button.classList.remove(...this.selectedClasses)
      }
    })
  }

  useDefaultIconClasses() {
    this.colorPreviewTarget.classList.remove(...this.darkIconClasses)
    this.colorPreviewTarget.classList.remove(...this.lightIconClasses)
    this.colorPreviewTarget.classList.add(...this.defaultIconClasses)
  }

  useDarkIconClasses() {
    this.colorPreviewTarget.classList.remove(...this.defaultIconClasses)
    this.colorPreviewTarget.classList.remove(...this.lightIconClasses)
    this.colorPreviewTarget.classList.add(...this.darkIconClasses)
  }

  useLightIconClasses() {
    this.colorPreviewTarget.classList.remove(...this.defaultIconClasses)
    this.colorPreviewTarget.classList.remove(...this.darkIconClasses)
    this.colorPreviewTarget.classList.add(...this.lightIconClasses)
  }
}
