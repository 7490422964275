import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ["groupSelect"];
  choices = [];

  connect() {

  }

  disconnect() {
    this.choices.forEach(choice => {
      choice.destroy();
    });
    this.choices = [];
  }
}
