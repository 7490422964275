import consumer from "./consumer"
import { Notyf } from "notyf"
import stop from '../src/features/stop'
import registerMessagesChannel from '../src/features/registerMessagesChannel'
import flipHandler from '../src/features/flipHandler'

import setWordPositions from '../src/features/setWordPositions'
import goTo from '../src/features/goTo'
import clearAfter from '../src/features/clearAfter'
import grayOutPrevious from '../src/features/grayOutPrevious'
import fetchAndRenderScript from '../src/features/fetchAndRenderScript'
import setScriptMarginTop from '../src/utility/setScriptMarginTop'
import isNumeric from '../src/utility/isNumeric'
import restart from "../src/features/restart"
import setTextSize from "../src/store/helpers/setTextSize"
import scrollToLine from "../src/utility/scrollToLine"

let displayedConnectedMessage = false
let notyf
let deviceWidthTimer

document.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('#script-body')) return;

  const { scriptId } = document.querySelector('#script-body').dataset

  const MessagesChannel = consumer.subscriptions.create(
    {
      channel: 'MessagesChannel',
      script_id: scriptId
    }, {
    connected(data) {
      notyf = new Notyf({
        duration: 7000,
        dismissible: true,
        position: {
          x: 'center',
          y: 'bottom',
        }
      });

      // notyf.success('Connected to another device(s).');
    },
    disconnected() {
      if (displayedConnectedMessage) {
        Logger('Disconnected...')
        notyf.error('Disconnected from other device(s).');
        Uatu.emit('remote_disconnected')
      }
    },
    received(data) {
      const currentPageOrigin = document.querySelector('body').dataset.origin

      const { action, script, user, time } = data
      const incomingActionOrigin = data.origin
      if (currentPageOrigin == incomingActionOrigin) return

      if (!displayedConnectedMessage) {
        displayedConnectedMessage = true
        notyf.success('Connected to another device(s).');
        mixpanel?.track('teleprompter:remote_connected')
        Uatu.emit('remote_connected')
      }

      Logger('received action - ${incomingActionOrigin}', action)

      if (state.timer) state.timer.resetInterval()

      if (action === 'restart') {
        restart()
      }

    if (action === 'mirror') {
        flipHandler()
      }

      if (action.includes('text-size')) {
        const size = action.split(':')[1]
        setTextSize(size)
        setWordPositions()
      }

      if (action.includes('alignment')) {
        const alignment = action.split(':')[1]
        document.querySelector('.teleprompter').style.textAlign = alignment
      }

      if (action.includes('resize')) {
        let [first, value, pixels] = action.split(':')
        const $script = document.querySelector('#script')

        if (window.innerWidth > parseFloat(pixels)) { // if its room, use actual pixels
          $script.style.width = `${parseFloat(pixels)}px`
        } else {
          $script.style.width = `100vw`
        }

        setTimeout(() => {
          // calculate width of the screen
          document.querySelectorAll('.js-width-slider').forEach((slider) => {
            const percentage = Math.round( $script.clientWidth / window.innerWidth * 100)
            slider.value = percentage
          })

          setWordPositions()
        }, 500) // wait for animation to finish
      }

      if (action.includes('scroll') && !action.includes('scrollMethod')) {
        const [param, wordNum, to] = action.split(':')

        const wordElement = document.querySelector(`span[data-num="${wordNum}"]`)
        if (wordElement) {
          const position = wordElement.dataset.position

          if (to == 'bookmark') {
            goTo(position, 500) // hurry up and go
          } else {
            goTo(position)
          }

          clearAfter(wordElement)
          grayOutPrevious(wordElement);
          wordElement.classList.add('spoken')
          window.state.lastWordNum = wordElement.dataset.num

          document.querySelector('.start-point')?.classList?.remove('start-point')
          wordElement.classList.add('start-point')

        } else {
          Logger('Word element not found', wordElement)
        }
      }

      if (action.includes('scrollMethod')) {
        let [first, param, value] = action.split(':')
        setStore('settings.mode', value)
      }

      if (action.includes('speed_change')) {
        let [first, speed] = action.split(':')
        Uatu.emit('speed_change', {
          speed: speed
        })
      }

      if (action.includes('scrollToLine:')) {
        let [param, direction, duration] = action.split(':')
        scrollToLine(direction, duration)
      }

      if (action.includes('setLineHeight')) {
        let [param, lineHeight] = action.split(':')
        setLineHeight(lineHeight)
      }

      if (action == 'force-restart') {
        window.location.reload()
      }

      if (action == 'fetch-script') {
        fetchAndRenderScript()
      }

      if (action.includes('setStore')) {
        let [first, attr, value] = action.split(':')
        value = isNumeric(value) ? parseFloat(value) : value
        value = ['true', 'false'].includes(value) ? JSON.parse(value) : value
        setStore(attr, value)
      }

      if (action.includes('setState')) {
        let [first, param, value] = action.split(':')
        value = isNumeric(value) ? parseFloat(value) : value
        value = ['true', 'false'].includes(value) ? JSON.parse(value) : value
        state[param] = value

        if (param == 'initiator') {
          document.querySelector('.initiator-status').classList.remove('active')
        }

        if (param == 'topOffset') {
          window.scrollTo(0, 0)
          document.querySelector('#read_line').value = window.state.topOffset
          setScriptMarginTop()
          setWordPositions()
        }
      }

      if (action == 'gather-device-widths') {
        window.Remote.action(`device-width:${document.querySelector('#script').clientWidth}px`)
      }

      if (action.includes('device-num-lines')) {
        const [param, numLines] = action.split(':')
        if (!window.deviceNumLines) {
          window.deviceNumLines = []
        }

        window.deviceNumLines.push(parseInt(numLines))
      }

      if (action.includes('device-width:')) {
        const [param, width] = action.split(':')

        // account for the width of the current window
        window.remoteDeviceWidths.push(document.querySelector('#script').clientWidth)

        // add in the others
        window.remoteDeviceWidths.push(parseFloat(width.match(/\d{0,}/)))

        clearTimeout(deviceWidthTimer)

        deviceWidthTimer = setTimeout(function () {
          const sortNumbers = (arr) => arr.sort((a, b) => a - b);
          let smallestWidth = sortNumbers(window.remoteDeviceWidths)[0]

          document.querySelector('#script').style.width = `${smallestWidth}px`
          window.Remote.action(`resize:null:${smallestWidth}px`)

          // set slider width
          document.querySelectorAll('.js-width-slider').forEach((slider) => {
            const percentage = Math.round(smallestWidth / window.innerWidth * 100)
            slider.value = percentage
            Uatu.emit('set_positions')
            setWordPositions()
          })
          document.querySelector('#sync-widths .fa-spin').classList.add('hidden')
        }, 3000)
      }
    },
  })

  registerMessagesChannel(MessagesChannel)
})
