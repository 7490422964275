import { Controller } from "@hotwired/stimulus"
import { Notyf } from "notyf";

export default class extends Controller {
  static targets = ["source"];

  copy() {
    this.sourceTarget.select();  // Selects the text inside the input
    document.execCommand('copy');  // Copies the selected text

    // Display a notification
    new Notyf().success('URL copied to clipboard!');
  }
}
