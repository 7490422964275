import isElement from '../utility/isElement'
import clearAfter from "../features/clearAfter";
import goTo from "../features/goTo";
import grayOutPrevious from "../features/grayOutPrevious";
import registerWordAsSpoken from "../features/registerWordAsSpoken";
import { Notyf } from 'notyf';

let notyf;

document.addEventListener("DOMContentLoaded", function () {
  notyf = new Notyf({
    duration: 5000,
    dismissible: true,
    position: {
      x: 'right',
      y: 'bottom',
    }
  });
})

const scrollToLine = (direction, duration) => {
  // handle if we already scrolled to the last line
  if (window.state.autoScrollPosition >= window.state.positions.length) {
    if (window.state.scrollMethod == 'voice') {
      setStore('prompter.active', false)
      if (!document.querySelector('.notyf__wrapper')) {
        notyf.success('Reached the end.')
      }
    }
    return
  }

  let positionsIndex = window.state.autoScrollPosition || 0

  // get the position, then go to it
  let goToPosition = 0
  if (positionsIndex > 0) {
    goToPosition = direction == 'down' ? positionsIndex : positionsIndex - 1
  }

  const goToPositionValue = window.state.positions[goToPosition]

  goTo(goToPositionValue, duration)

  // increment or decrement for next time
  if (direction == 'down') {
    positionsIndex += 1
  } else if (positionsIndex > 0) {
    positionsIndex -= 1
  }

  let elementAtPosition = document.querySelector(`[data-position="${goToPositionValue}"]`)
  if (!elementAtPosition) return

  if (isElement(elementAtPosition, 'span')) {
    registerWordAsSpoken(elementAtPosition, { skipSetAutoScrollPosition: true })
  } else {
    const isBlankParapgrah = Array.from(elementAtPosition.children).join('') == '[object HTMLBRElement]'
    if (!isBlankParapgrah) {
      elementAtPosition = elementAtPosition.querySelector(`span:not(.bracketed)[data-position="${goToPositionValue}"]`)
      registerWordAsSpoken(elementAtPosition, { skipSetAutoScrollPosition: true })
    } else {
      // only do this if its not active to prevent "jumping" on auto mode
      if (['flow', 'manual'].includes(state.scrollMethod)) {
        window.Remote.action(`scrollToLine:${direction}:${duration}`)
      }
    }
  }

  window.state.autoScrollPosition = positionsIndex

  if (direction == 'up') {
    clearAfter(elementAtPosition)
  } else {
    grayOutPrevious(elementAtPosition, state.wordsPerLine * 3)
  }
}
window.scrollToLine = scrollToLine
export default scrollToLine
