// chat_rating_controller.js
import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = ["input", "answer", "wrap"]

  submitPositiveRating() {
    this.submitRating(true)
  }

  submitNegativeRating() {
    this.submitRating(false)
  }

  submitRating(helpful) {
    const ratingData = {
      question: this.inputTarget.value,
      answer: this.answerTarget.textContent,
      helpful: helpful
    }

    axios.post("/chat/rating", ratingData, {
      headers: {
        'X-CSRF-Token': this.getMetaValue("csrf-token")
      }
    })
    .then(response => {
      console.log("Rating submitted successfully:", response.data)
      this.showThankYouMessage()
    })
    .catch(error => {
      console.error("Error submitting rating:", error)
    })
  }

  showThankYouMessage() {
    this.wrapTarget.innerHTML = '<p class="text-gray-600 text-center w-full">Thanks for your feedback!</p>'
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}
