import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dialog" ]

  requestModalVariant(event) {
    event.detail.url.searchParams.append('view', 'modal')
  }

  showModal() {
    window.Modal.show(this.element.id)
  }

  closeModal() {
    window.Modal.close(this.element.id)
  }

  get dialog() {
    return this.hasDialogTarget ? this.dialogTarget : this.element
  }
}
